/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import "./node_modules/bootstrap/dist/css/bootstrap.min.css"
import "./src/styles/custom.scss"
